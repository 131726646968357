import './CVMenu.css';
import React, { useEffect, useState} from 'react';
import $ from "jquery"

function CVMenu({items, parent}) {

    const [activeBlock, setActiveBlock] = useState(undefined);
    const [expanded, setExpanded] = useState(false);

    const onScroll = () => {
        $('.cv-block').each(function () {
            if($(this).offset().top <= $(document).scrollTop() + $(window).height()*0.3 && ($(this).offset().top + $(this).outerHeight() ) > $(document).scrollTop()) {
                setActiveBlock($(this).attr('id'));
            }
            if($(document).scrollTop() + $(window).height() + 50 >= $(document).height()){
                setActiveBlock($('.cv-block').last().attr('id'));
            }
        });
    }

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return(() => {
            document.removeEventListener("scroll", onScroll);
        });
    });

    const handleExpand = () => {
        console.log(expanded);
        setExpanded(!expanded);
    }

    const handleWrapperClick = (e) => {
        if(e.currentTarget !== e.target ) return;
        if(expanded){
            setExpanded(false);
        }
    }

    return (
        <div id={'cv-menu-wrapper'} className={expanded ? 'expanded' : ''} onClick={handleWrapperClick}>
            <div id={`cv-menu`} className={expanded ? 'expanded' : ''}>
                <div id={"cv-menu-expand"} className={"cv-menu-item"} onClick={handleExpand}>
                    <div>
                        <i className="material-icons  material-icons.md-light" >menu</i>
                    </div>
                </div>
                <div>
                    {items.map((i) => <a href={`#${i.targetId}`} key={`menu-${i.targetId}`} className={`cv-menu-item ${activeBlock === i.targetId ? 'selected' : ''}`}>
                        {i.text}
                    </a>)}
                </div>
            </div>
        </div>
    );
}

export default CVMenu;
