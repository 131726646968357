import ExperienceItem from "./components/ExperienceItem";
import ImgMe from "./components/ImgMe";
import SkillList from "./components/SkillList";
import ContactBlock from './components/ContactBlock';

const workExperience = [
    {
        id: 'codelearn',
        title: 'Codelearn',
        subtitle: 'Tech Lead & Ruby on Rails full stack developer',
        place: 'Manresa, Catalonia',
        date: 'Since March 2018',
        link: 'https://www.codelearn.com',
        niceLink: 'codelearn.com',
        description: <div>
            With centers all around Catalonia and with presence in Spain, Codelearn is a programming and computational thinking academy for children.
            They provide a fun and intuitive online platform which includes many features to achieve an interesting, immersive and entertaining learning experience.

            <br/><br/>I lead the small team developing this platform.
            What I love most about my job is the opportunity to handle tasks that allow me both the freedom and responsibility to be creative and proactive.

            <br/><br/><b>2018</b><br/> I started as an intern and began working full-time as a full-stack developer soon after.
            <br/><b>2019-2023</b><br/> I continued to gain responsibilities as new talent joined our development team.
            <br/><b>2024</b><br/> With over six years at the company, I am proud to be Codelearn's Tech Lead.

            <br/><br/> My current github contribution chart:
            <img id={"github-chart"} src="https://ghchart.rshah.org/ff447f/raquelhortab" alt="raquelhortab's Github chart" title="raquelhortab's Github chart - thanks to https://github.com/2016rshah" />
        </div>
    },
    {
        id: 'opentrends',
        title: 'Opentrends',
        subtitle: 'Android Developer - Internship',
        place: 'Barcelona, Catalonia',
        date: 'September 2016 - March 2018',
        link: 'https://www.opentrends.net',
        niceLink: 'www.opentrends.net',
        description: 'Development of public mobile apps for the Catalan Government, including Rodalies and Infoconsum. ' +
            '\n\nI helped as a backend developer for a Java Spring project.' +
            '\n\nFinally, I was the only developer in a project consisting in the automation of the app metrics report generation process. This project was developed in Java and Latex.'
    }
];

const workExperienceItems = workExperience.map((item)=><ExperienceItem key={item.id} {...item}/>);

const education = [
    {
        id: 'uned',
        title: 'National Distance Learning University (UNED)',
        subtitle: 'Master Degree in Research in Artificial Intelligence',
        place: undefined,
        date: '2020-2023',
        link: 'http://portal.uned.es/portal/page?_pageid=93,69882142&_dad=portal&_schema=PORTAL',
        niceLink: 'uned.es',
        description: '',
        itemLists: [{
            title: 'Subjects',
            items: ['Educational Adaptive Systems', 'Data Mining', 'Evolutionary Computing', 'Graphical Probabilistic Models', 'Neural Networks for Natural Language Processing', 'Methodologies for Research in Intelligent Systems']
        },{
            title: 'Final project',
            items: [<i>A time-aware approach to detect help-seeking behaviour from student-platform interaction.</i>, 'Grade: 10 with honours']
        }]
    },
    {
        id: 'upc-school',
        title: 'UPC School - Barcelona Tech',
        subtitle: 'Big Data: Generation, Analysis and Data Protection',
        place: 'Universitat Politècnica de Catalunya',
        date: '2019-2020',
        link: 'https://www.talent.upc.edu',
        niceLink:'www.talent.upc.edu',
        description: 'Postgraduate course (15 ECTS), grade: 9.2'
    },
    {
        id: 'fib',
        title: 'Barcelona School of Informatics FIB',
        subtitle: 'Computer Engineering Bachelor Degree - Computer Science',
        place: 'Universitat Politècnica de Catalunya',
        date: '2014 - 2019',
        link: 'https://www.fib.upc.edu',
        niceLink: 'fib.upc.edu',
        description: '',
        itemLists:[
            {
                title: 'Elective subjects',
                items: ['Machine Learning', 'Control and Industrial Automation', 'Automated Manufacture and Industrial Robotics']
            },
            {
                title: 'Final project',
                items: [<i>A machine learning approach to calculate the dropout risk factor in a children's programming school.</i>, 'Grade: 9.3 out of 10']

            }
        ],
    },
    {
        id: 'highschool',
        title: 'IES Gerbert d\'Aurillac',
        subtitle: 'Technological High School',
        place: 'Sant Fruitós de Bages',
        date:'2012 - 2014',
        description: 'Final project on Electromagnetic Fields of High Voltage Lines and how they may affect on ' +
            'people living nearby. For this project, I was awarded two third place prizes.' +
            '\n\nAmong the best 1% on University entrance exams, Catalonia\'s Government distinction.'
    }
];

const educationItems = education.map((item)=><ExperienceItem key={item.id} {...item}/>);

const skills = [
    {
        title: 'Tech stack',
        id:'tech_stack',
        items:[
            {text: 'Ruby on Rails', percentage: 80},
            {text: 'HTML, CSS', percentage: 80},
            {text: 'MySQL', percentage: 60},
            {text: 'Git', percentage: 70},
            {text: 'React', percentage: 60},
            {text: 'Python', percentage: 60},
            {text: 'Docker', percentage: 70},
            {text: 'Latex', percentage: 50},
            {text: 'Android', percentage: 30},
        ],
        alignRight: true
    },
    {
        title: 'Personal Competences',
        id: 'personal',
        items:[
            {text: 'Hard-working'},
            {text: 'Creative'},
            {text: 'Autonomous'},
        ]
    },
    {
        title: 'Values',
        id: 'values',
        items:[
            {text: 'Critical-thinking'},
            {text: 'Ethical and socially aware'},
            {text: 'Environmentally conscious'},
            {text: 'Honest'},
        ]
    },
    {
        title: 'Languages',
        id: 'languages',
        items:[
            {text: 'Catalan:', description:'native'},
            {text: 'Spanish:', description:'native'},
            {text: 'English:', description:'Cambridge Advanced Certiﬁcate, Grade A'},
        ]
    }
];

const achievements = [
    {
        title: "Hackathon winner",
        subtitle: "Geekle AI Hackathon 2024 ",
        description: <div>
            <i>Phase 1 - Solution Finder: winning team</i>
            <br/><br/>
            Along with five friends, I participated in the first Geekle AI hackathon, which aimed to build the foundation of the new Geekle AI platform.
            Our team developed an assistant that finds software solutions and compares them by creating a comparison table based on user prompts.
            This project resulted in our team being awarded the top prize for the first phase of the competition.
        </div>,
        date: 'July 2024',
        link: 'https://geekle.ai/hackathon/first/',
        niceLink: 'geekle.ai'
    },
    {
        title: "Joined Eta Kappa Nu",
        subtitle: "IEEE's HKN Honor Society",
        description: <div>
            <i>Nu Alpha Chapter</i>
            <br/><br/>
            I was reached out by the Nu Alpha chapter of IEEE's Eta Kappa Nu academic excellence society.
            In order to join this honor society, one must first be formally invited to apply.
            These invitations are based on the students' academic record.
            After applying, a committee considers all applications and selects up to 40% of the applicants.
            I have been honoured to be selected as one of the new members of the Nu Alpha chapter in 2022 and I look forward to see what opportunities and projects emerge from this.
            IEEE is the top publisher in the engineering fields and this is therefore an honor for me.
        </div>,
        place: 'UNED',
        date: 'June 2022',
        link: 'https://hkn.ieee.org/',
        niceLink: 'hkn.ieee.org'
    },
    {
        title: 'Published Doctoral Consortium paper',
        subtitle: 'International Conference in Educational Data Mining',
        description: <div>
            <i>A Time-Aware Approach to Detect Patterns and Predict Help-Seeking Behaviour in Adaptive Educational Systems.</i>
            <br/><br/>
            In distance education and some computer-assisted learning
            scenarios asking for help when needed is important. Some
            students do not ask for help even when they do not know
            how to proceed. In situations where a teacher is not present,
            this can be a serious setback. We aim to find an approach
            to learn about students’ help-seeking behaviour by studying
            sequences of actions that end with the student asking for
            help. The goal is to be able to recognize those students who
            need help but fail to ask for it and offer them assistance. We
            propose to include the temporal context of user-platform
            interaction and suggest an ensemble model to learn from
            both general and personal tendencies</div>,
        place: 'EDM2021',
        date: 'July 2021',
        link: 'https://educationaldatamining.org/EDM2021/virtual/static/pdf/EDM21_paper_257.pdf',
        niceLink: 'educationaldatamining.org'
    }
];

const achievementItems = achievements.map((item)=><ExperienceItem key={item.id} {...item}/>);

const Sections = [
    {
        id: 'about-me',
        title: 'about me',
        content:<div className={'row'}>
            <div className={'col-md-12 col-lg-6'}>
                I consider myself a young woman in the progress of building the bedrock of her career.
                I am a computer science engineer and a full stack Ruby on Rails developer.
                I am currently studying a Master's degree in Research in Artificial Intelligence.
                <br/><br/>
                It is very important to me to have a job in which I feel useful and the goal of which is meaningful to me.
                For instance, I am interested in the field of computer science applied to education and I am focusing my master's research and learning on educational systems.
                I really enjoy learning new things, I love challenges and I might get bored with easy repetitive work.
                <br/><br/>
                I love traveling, my main hobby is rock climbing and I consider myself an animal lover and an environmentalist in progress.
                <br/><br/>

                <b>Name:</b> Raquel Horta Bartomeu<br/>
                <b>Location:</b> Central Catalonia<br/>
                <b>Email:</b> raquelhortab@gmail.com<br/>
            </div>
            <div id={'img-me-wrapper'} className={'col-md-12 col-lg-6'}>
                <ImgMe/>
            </div>
        </div>
    },
    {
        id: 'work-experience',
        title: 'work\n experience',
        content: workExperienceItems
    },
    {
        id: 'education',
        title: 'education',
        content: educationItems
    },
    {
        id: 'skills',
        title: 'skills and\n certificates',
        content: <div>
            {skills.map((list)=><SkillList {...list}/>)}
        </div>
    },
    {
        id: 'achievements',
        title: 'achievements',
        content: achievementItems
    },
    {
        id: 'contact',
        title: 'contact',
        content: <ContactBlock/>
    }
];

export default Sections;
