import me360px from "../images/me-360px.png";
import me372px from "../images/me-720px.png";
import './ImgMe.css';

function ImgMe(){
    return(<img
        alt={'me'}
        id={'img-me'}
        className={'img-responsive'}
        srcSet={`${me360px} 360w, ${me372px} 720w`}
        src={me360px}></img>);
};

export default ImgMe;