import './ExperienceItem.css';
import BulletList from './BulletList';

function ExperienceItem({title, subtitle, place, description, date, link= undefined, niceLink = undefined, itemLists = undefined}) {
    return (
        <div className={'row experience-item'}>
            <div className="col-md-12 col-lg-5">
                {title && <div className={'title'}>{title}</div>}
                {place && <div className={'place'}>{place}</div>}
                {date && <div className={'date'}>{date}</div>}
                {link && niceLink &&
                    <div className={'link'}>
                        <i className="material-icons color-accent icon-align">place</i> <a href={link} target="_blank" rel="noreferrer">{niceLink}</a>
                    </div>}
            </div>
            <div className="col-md-12 col-lg-7">
                <div className={'subtitle'}>{subtitle}</div>
                <div className={'description'}>
                    {description}
                </div>
                {itemLists && itemLists.map((itemList)=><BulletList title={itemList.title} items={itemList.items}/>)}
            </div>
        </div>
    );
}

export default ExperienceItem;