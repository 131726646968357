import './BulletList.css'

function BulletList({title, items}){
    return(
        <div className={'bullet-list'}>
            <span className={'bullet-list-title'}>{title}:</span>
            <ul>
                {items.map((item) => <li>{item}</li>)}
            </ul>
        </div>
    )
}

export default BulletList;