import './ContactBlock.css';
import email from '../images/email.svg';
import linkedin from '../images/linkedin.svg';
// import phone from '../images/phone.svg';

function ContactBlock(){
    return(
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <a className={"contact-icon"} href={"mailto:raquelhortab@gmail.com"} target={'_blank'} rel={"noopener noreferrer"}>
                        <object data={email} title={'email'} type={"image/svg+xml"}></object>
                    </a><br/>
                    <a className={"contact-link"} href={"mailto:raquelhortab@gmail.com"} target={'_blank'} rel={"noopener noreferrer"}>raquelhortab@gmail.com</a>
                </div>
                <div className={"col-md-6"}>
                    <a className={"contact-icon"} href={"https://www.linkedin.com/in/raquel-horta-bartomeu/"} target={'_blank'} rel={"noopener noreferrer"}>
                        <object data={linkedin} title={'linkedin'} type={"image/svg+xml"}></object>
                    </a><br/>
                    <a className={"contact-link"} href={"https://www.linkedin.com/in/raquel-horta-bartomeu/"} target={'_blank'} rel={"noopener noreferrer"}>LinkedIn</a>
                </div>
                {/*<div className={"col-md-4"}>*/}
                {/*    <a className={"contact-icon"} href={"tel:+34 622588944"}>*/}
                {/*        <object data={phone} title={'phone'} type={"image/svg+xml"}></object>*/}
                {/*    </a><br/>*/}
                {/*    <a className={"contact-link"} href={"tel:+34 622588944"}  >+34 622588944</a>*/}
                {/*</div>*/}
            </div>
            <div className={"row"} >
                <div className={"col-md-12"}>
                    <a className={"contact-link"} href={process.env.PUBLIC_URL +"/raquel_horta_work_cv.pdf"} target={"_blank"} rel={"noopener noreferrer"}>
                        Download pdf resume</a>
                </div>
            </div>
        </div>
    );
}
export default ContactBlock;