import SkillItem from './SkillItem';

function SkillList({items, title, id = '', alignRight = false}){
    return(
        <div className={`skill-list ${id} ${alignRight && 'align-right'}`} id={id}>
            <div className={'skills-title'}>{title}</div>
            <div className={'list'}>
                {items.map((item) => <SkillItem key={item.id} {...item}/>)}
            </div>
        </div>
    );
};

export default SkillList;