import './CVBlock.css';

function CVBlock({title, id, children, bg = 'white'}) {
    return (
        <div id={id} className={`cv-block bg-${bg}`}>
            <h1 className="title">{title}</h1>
            <div className="divider"></div>
            <div className={'cv-block-content'}>
                {children}
            </div>
        </div>
    );
}

export default CVBlock;
