import './App.css';
import './typewritter.css';
import CVBlock from "./components/CVBlock";
import CVMenu from "./components/CVMenu";
import Sections from "./sections";

function App() {
  return (
    <div className="app">
        <div id={'background'}></div>
        <div id={'header'}>
            <div id={'title-text'} className={'typewriter'}>
                <h1>raquel horta</h1>
            </div><br/>
            <div id={'title-sub-text'} className={'typewriter'}>
                <h4>resume</h4>
            </div>

            <a href={`#about-me`} id="cv-menu-arrow" className="cv-menu-item selected">
                <div className="centering-content"><i className="material-icons material-icons.md-light">keyboard_arrow_down</i></div>
            </a>
        </div>
        <div id={'content'}>
            <CVMenu items={Sections.map((i) => ({text: i.title, targetId: i.id}) )}/>
            {Sections.map((i, index) =>
                <CVBlock bg={index % 2 === 0 ? 'white' : 'floral'} title={i.title} id={i.id} key={i.id}>
                    {i.content}
                </CVBlock>
            )}
        </div>
    </div>
  );
}

export default App;
